import { useEffect, useState } from "react";
import { getHomeData, getProjectData } from "../utilities/api";
import { Link } from "react-router-dom";
import "animate.css";

const Home = () => {
  const [homeData, setHomeData] = useState({});
  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    getHomeData().then((data) => {
      setHomeData(data);
    });
  }, []);

  useEffect(() => {
    // Array of project IDs
    const projectIDs = [104, 93, 130];

    // Fetch data for each project sequentially
    const fetchProjectData = async () => {
      const projects = [];
      for (const id of projectIDs) {
        const project = await getProjectData(id);
        projects.push(project);
      }
      setProjectData(projects);
    };

    fetchProjectData();
  }, []);

  return (
    <div className="home-container">
      {/* Landing page */}
      <div className="section landing-section" id="landing">
        <h1 className="name">{homeData?.[0]?.acf?.name}</h1>
        <h2 className="animate__animated animate__fadeInUp">
          {homeData?.[0]?.acf?.role}
        </h2>
        {/* Social Media Icons */}
        <div className="social-icons">
          {homeData?.[0]?.acf?.social_1 && (
            <a
              href="https://github.com/nandohsp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="icon-wrapper">
                <svg
                  className="github-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                </svg>
              </div>
            </a>
          )}
          {homeData?.[0]?.acf?.social_2 && (
            <a
              href="https://www.linkedin.com/in/nandohospina/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="icon-wrapper">
                <svg
                  className="linkedin-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                </svg>
              </div>
            </a>
          )}
        </div>
      </div>

      {/* About me section */}

      <div className="section about-section" id="about">
        <section className="about-me-container">
          <h2 className="about-me-heading">
            {homeData?.[0]?.acf?.about_heading}
          </h2>
          <p>{homeData?.[0]?.acf?.about_text}</p>
          <p>{homeData?.[0]?.acf?.about_text_2}</p>
          <p>{homeData?.[0]?.acf?.about_text_3}</p>
        </section>
        <section className="about-tools-container">
          <h2 className="about-tools-heading">
            {homeData?.[0]?.acf?.tools_heading}
          </h2>
          <ul className="tool-list">
            {homeData?.[0]?.acf?.tools &&
              homeData?.[0]?.acf?.tools.map((tool, index) => (
                <li key={index}>{tool}</li>
              ))}
          </ul>
        </section>
        <section className="about-interests-container">
          <h2 className="about-interests-heading">
            {homeData?.[0]?.acf?.interests_heading}
          </h2>
          <ul className="interest-list">
            {homeData?.[0]?.acf?.interests &&
              homeData?.[0]?.acf?.interests.map((interest, index) => (
                <li key={index}>{interest}</li>
              ))}
          </ul>
        </section>
      </div>

      {/* Projects section */}
      <div className="section projects-section" id="projects">
        <h1>{homeData?.[0]?.acf?.projects_heading}</h1>
        <div className="projects-container">
          {projectData.map((project) => (
            <section key={project.id} className="project-item">
              <h3>{project?.acf?.project_name}</h3>
              <img
                className="project-image"
                src={project?.acf?.project_image.url}
                alt="Screenshot of the project's homepage"
              />

              <p>{project?.acf?.project_excerpt}</p>
              <Link to={`/project/${project.id}`} className="details-link">
                <span className="details-link-text">Details</span>

                <svg
                  className="details-link-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z" />
                </svg>
              </Link>
            </section>
          ))}
        </div>
      </div>

      {/* Contact section */}
      <div className="section contact-section" id="contact">
        <h2>{homeData?.[0]?.acf?.contact_heading}</h2>
        <p>{homeData?.[0]?.acf?.contact_text}</p>
        {/* <div className="contact-email">
          <p>{homeData?.[0]?.acf?.email}</p>
        </div> */}
        <div className="contact-social-icons">
          <a
            href={`mailto:${homeData?.[0]?.acf?.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="icon-wrapper">
              <svg
                className="envelope-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z" />
              </svg>
            </div>
          </a>
          {homeData?.[0]?.acf?.social_1 && (
            <a
              href="https://github.com/nandohsp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="icon-wrapper">
                <svg
                  className="github-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                </svg>
              </div>
            </a>
          )}
          {homeData?.[0]?.acf?.social_2 && (
            <a
              href="https://www.linkedin.com/in/nandohospina/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="icon-wrapper">
                <svg
                  className="linkedin-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                </svg>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
