import loading from "../images/loading-spinner.gif";

const Loading = () => {
  return (
    <img
      src={loading}
      alt="Loading"
      className="loading-spinner"
      id="loading-spinner"
    />
  );
};

export default Loading;
