import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProjectData } from "../utilities/api";
import Loading from "./Loading";

const Project = () => {
  const [projectData, setProjectData] = useState({});
  const { projectId } = useParams();
  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    getProjectData(projectId).then((data) => {
      setProjectData(data);
      setLoadStatus(true);
    });
  }, [projectId]);

  if (!isLoaded) {
    return <Loading />;
  }

  // Function to toggle accordion
  const toggleAccordion = (event) => {
    const accordionHeader = event.currentTarget;
    const content = accordionHeader.nextElementSibling;
    const arrowIcon = accordionHeader.querySelector(".arrow-icon"); // Select the arrow icon within the clicked accordion header
    accordionHeader.classList.toggle("active");
    content.classList.toggle("show");
    arrowIcon.classList.toggle("rotate"); // Toggle the rotation of the arrow icon
  };

  return (
    <div className="project-container">
      <h1>{projectData?.acf?.project_name}</h1>
      {/* Display the project image */}

      <div className="video-container">
        {projectData?.acf?.project_video?.url && (
          <video
            src={projectData?.acf?.project_video.url}
            controls
            controlsList="nodownload"
            width="600px"
          ></video>
        )}
        {projectData?.acf?.project_main_image && (
          <img
            className="project-main-image"
            src={projectData.acf.project_main_image.url}
            alt="Screenshot of the project's homepage"
          />
        )}
      </div>

      <div className="project-tools-link">
        {projectData?.acf?.technology && (
          <ul className="technology-list">
            {projectData?.acf?.technology.map((tech, index) => (
              <li key={index}>{tech}</li>
            ))}
          </ul>
        )}
        <section>
          <a
            href={projectData?.acf?.project_link}
            target="_blank"
            rel="noopener noreferrer"
            className="site-link"
          >
            <span className="site-link-text">Live site</span>
            <svg
              className="site-link-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z" />
            </svg>
          </a>
        </section>
      </div>
      <div className="project-overview">
        <h2>Overview</h2>

        <div
          className="entry-content"
          dangerouslySetInnerHTML={{
            __html: projectData?.acf?.project_details,
          }}
        ></div>
      </div>

      {/* Planning and Executions section */}
      <div className="accordion-container">
        <div className="project-execution">
          <div className="accordion-header" onClick={toggleAccordion}>
            {" "}
            {/* Wrap h2 and SVG in a parent container */}
            <h3>Process and Highlights</h3>
            <svg
              className="arrow-icon arrow-down" // Initially, arrow-down icon
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
            </svg>
          </div>

          <div className="content">
            <div
              className="entry-content"
              dangerouslySetInnerHTML={{ __html: projectData?.acf?.execution }}
            ></div>
          </div>
        </div>

        {/* Learnings section */}
        <div className="project-reflection">
          <div className="accordion-header" onClick={toggleAccordion}>
            <h3>Reflection</h3>
            <svg
              className="arrow-icon arrow-down" // Initially, arrow-down icon
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
            </svg>
          </div>
          <div className="content">
            <div
              className="entry-content"
              dangerouslySetInnerHTML={{
                __html: projectData?.acf?.reflection,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
