// function for the footer content

function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="footer-copyright">
        <p>&copy; 2024 Nando Hospina</p>
      </div>
    </div>
  );
}

export default Footer;
