// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BrowserRouter, Router, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import Home from "./components/Home";
import Project from "./components/Project";
import Footer from "./components/Footer";

function App() {
  return (
    // <Router>
    <BrowserRouter>
      <>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project/:projectId" element={<Project />} />
        </Routes>
        <Footer />
      </>
      {/* </Router> */}
    </BrowserRouter>
  );
}

export default App;
