import { HashLink } from "react-router-hash-link";

function Nav() {
  const scrollWithDelay = (el, delay) => {
    setTimeout(() => {
      el.scrollIntoView({ behavior: "smooth" });
    }, delay);
  };

  return (
    <div className="nav-wrapper">
      <h3 className="logo">
        <a href="/">Nando Hospina</a>
      </h3>
      <nav className="nav-links">
        <ul>
          <li>
            <HashLink
              smooth
              to="/#about"
              scroll={(el) => scrollWithDelay(el, 500)}
            >
              About
            </HashLink>
          </li>
          <li>
            <HashLink
              smooth
              to="/#projects"
              scroll={(el) => scrollWithDelay(el, 500)}
            >
              Projects
            </HashLink>
          </li>
          <li>
            <HashLink
              smooth
              to="/#contact"
              scroll={(el) => scrollWithDelay(el, 500)}
            >
              Contact
            </HashLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Nav;
