function getHomeData() {
  return fetch(
    `https://nandowebdev.com/my-portfolio/wp-json/wp/v2/pages?_embed`
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .catch((error) => {
      throw error;
    });
}

function getProjectData(projectId) {
  return fetch(
    `https://nandowebdev.com/my-portfolio/wp-json/wp/v2/project/${projectId}?acf_format=standard`
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .catch((error) => {
      throw error;
    });
}

export { getHomeData, getProjectData };
